import dayjs from 'dayjs';

export const getDatesFromDateRange = (dateRange: string[]): string[] => {
    console.log(dateRange)
    let dates: string[] = [];
    // push firstDate
    dates.push(dateRange[0]);
    // get Inbetween Dates
    // calculate number of nights
    const dateStart = dayjs(dateRange[0])
    const dateEnd = dayjs(dateRange[1])
    const nights = dateEnd.diff(dateStart, 'day');
    console.log(nights)
    //iterate for middle dates
    if (nights > 1) {
        // console.log('HI')
        for (let i = 1; i <= nights; i++) {
            let middleDate: any = dayjs(dateStart).add(i, 'day').format('YYYY/MM/DD')
            dates.push(middleDate)
        }
    }
    return dates;
}