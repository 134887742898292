import './App.css';
import React from 'react';
import HomePage from './Pages/Homepage/homepage';
import { Routes, Route } from 'react-router-dom';
import PlanATrip from './Pages/RoutePlan/routeplan';
import { useLoadScript } from '@react-google-maps/api';
import FoodModule from './Pages/Food/food_component';
import RestaurantComponent from './Pages/Restaurants/restaurant_component';
import CartPage from './Pages/Cart/cart.component';
import PaymentSuccess from './CommonComponents/paymentSuccess/paymentsuccess';
import CommingSoon from './CommonComponents/commingsooncomponent/commingsoon';
import CompanyDetails from './Pages/CompanyDetails/compantDetails.component';


function App() {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyCnLJMmRlWDOHvJzuiD9rODW0PGra65nH4",
    libraries: ['drawing', 'geometry', 'places'],
  });
  return (
    <div>
      <Routes>
        <Route path="planatrip" element={<PlanATrip isLoaded={isLoaded} />} />
        <Route path="/" element={<HomePage />} />
        <Route path="/food" element={<FoodModule />} />
        <Route path="*" element={<HomePage />} />
        <Route path='/restaurants/:restaurantId' element={<RestaurantComponent />} />
        <Route path='/cart/:restaurantId' element={<CartPage />} />
        <Route path='/paymentsuccess' element={<PaymentSuccess />} />
        <Route path='/commingsoon' element={<CommingSoon />} />
        <Route path='/:details' element={<CompanyDetails />} />
      </Routes>
    </div>

  );
}

export default App;
