/* eslint-disable */
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { setTripDetails } from '../planatripredux/pat_slice';
import { fetchLocationLATLONG, showPosition, fetchDirection } from './gmap.util';
import { fetchLocationSuccess, fetchLocationFailure, fetchRouteSuccess, fetchRouteFailure, setCustomRoute } from './gmapslice';

//locate device api call
export function* fecthDeviceLocation(): any {
  try {
    const currentPosition = yield fetchLocationLATLONG();
    const currentAddress = yield showPosition(currentPosition);
    yield put(fetchLocationSuccess(currentAddress));
  } catch (error) {
    yield put(fetchLocationFailure(error));
  }
}

//get routes api call
export function* fetchRoute({ payload }: any): any {
  try {
    const direction = yield fetchDirection(payload);
    console.log(direction)
    yield put(fetchRouteSuccess(direction));
    yield put(setCustomRoute(direction[0]));
    // yield put(setTripDetails(direction.tripDetails));
  } catch (error) {
    yield put(fetchRouteFailure(error));
  }
}

export function* fetchCustomRoute({ payload }: any): any {
  try {
    const direction = yield fetchDirection(payload);
    console.log(direction)
    yield put(setCustomRoute(direction[0]));
    // yield put(setTripDetails(direction.tripDetails));
  } catch (error) {
    console.log(error)
    yield put(fetchRouteFailure(error));
  }
}

export function* fetchLocationStart() {
  yield takeLatest('gmap/getLocationStart', fecthDeviceLocation);
}

export function* fetchRouteStart() {
  yield takeLatest('gmap/fetchRouteStart', fetchRoute);
}
export function* fetchCustomRouteStart() {
  yield takeLatest('gmap/fetchCustomRouteStart', fetchCustomRoute);
}

export function* gMapSaga() {
  yield all([call(fetchLocationStart), call(fetchRouteStart), call(fetchCustomRouteStart)]);
}
