import React, { useState } from 'react';
import { DatePicker, Space } from 'antd';
import './dateRangestyles.scss'
// import { RangePickerProps } from 'antd/es/date-picker';
const { RangePicker } = DatePicker;
import dayjs from 'dayjs';
// import customParseFormat from 'dayjs/plugin/customParseFormat';
import minMax from 'dayjs/plugin/minMax';
import isBetween from 'dayjs/plugin/isBetween';
const DateRange = (props: any) => {
    // dayjs.extend(customParseFormat);
    dayjs.extend(isBetween)
    dayjs.extend(minMax)

    // Disable Dates other than seleced date range
    const disabledDate: any = (current: any) => {
        if (props.dateRange) {
            return current && props.dateRange.findIndex((date: string) => dayjs(date).isSame(dayjs(current), 'day')) === -1
        } else if (props.futureDate) {
            // Can not select days before today and today
            return current && current <= dayjs().startOf('day');
        }
    };
    const [value, setValue] = useState(null);
    const setdateChangeDaterange = (dates: any, dateStrings: any) => {
        setValue(dates)
        props.dateChange(dateStrings)
    }
    return (
        <Space direction="vertical" size={12} className="date__width">
            <RangePicker open={props.openDateRange}
                disabledDate={disabledDate}
                style={props.style}
                className={props.class}
                bordered={false} format={'YYYY/MM/DD'} value={value} onCalendarChange={(dates, dateStrings) => setdateChangeDaterange(dates, dateStrings)} />
        </Space>
    );
};

export default DateRange;     