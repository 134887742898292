import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { gmapSlice } from './googleMapRedux/gmapslice';
import createSagaMiddleware from 'redux-saga';
import rootsaga from './root-saga';
import { patSlice } from './planatripredux/pat_slice';
import { userSlice } from './userredux/user_slice';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { restaurantSlice } from './restaurantRedux/restaurant_slice';
import { cartSlice } from './cartRedux/cartslice';
import { paymentSlice } from './paymentRedux/payment_slice';

const middleWares = [];
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user", "cart", "pat", "gmap"],
};
const reducers = combineReducers({ gmap: gmapSlice.reducer, pat: patSlice.reducer, user: userSlice.reducer, restaurants: restaurantSlice.reducer, cart: cartSlice.reducer, payment: paymentSlice.reducer });
const persistedReducer = persistReducer(persistConfig, reducers)
const SagaMiddleware = createSagaMiddleware();
middleWares.push(SagaMiddleware);

const devToolsReq = process.env.NODE_ENV === 'development' ? true : false;
export default configureStore({
  reducer: persistedReducer,
  middleware: [...middleWares],
  devTools: devToolsReq
});

SagaMiddleware.run(rootsaga);
