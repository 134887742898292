import { CloseCircleOutlined } from "@ant-design/icons";
import Radio from "antd/es/radio";
import React, { useState } from "react";
import DateRange from "../dateRangecomponent/daterangecomponent";
import './button_date.styles.scss'
import dayjs from 'dayjs';
const ButtonDate = (props: any) => {
    const [showDate, setShowDate] = useState(false);
    const [showRadio, setShowRadio] = useState(true);
    const [showDateSelection, setDateSelection] = useState(false);
    const [dates, setDate] = useState('');
    const handleRadioChange = (e: any) => {
        if (e.target.checked) {
            setShowDate(true)
        } else {
            setShowDate(false)
        }
    }
    const dateChange = (date: any) => {
        if (date[1] !== '') {
            const customDateRange: any = customFormatDate(date);
            setDate(customDateRange)
            setShowDate(false)
            setShowRadio(false)
            setDateSelection(true)
        }
        props.onDateSelection(date[0], date[1])
    }
    const customFormatDate = (date: [string, string]) => {
        let weekDay = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
        let correctDateFormat = ''
        // Sun25/12-Mon26/12
        //get shorthand weekDay
        //returns weekday number
        const startWeekDayNum = new Date(date[0]).getDay();
        const endWeekDayNum = new Date(date[1]).getDay();
        console.log(endWeekDayNum)
        // returns weekday 
        const startWeekDay = weekDay[startWeekDayNum]
        const endWeekDay = weekDay[endWeekDayNum]
        // change Date formate of startend date
        const startday = dayjs(date[0]).format('DD/MM')
        const endday = dayjs(date[1]).format('DD/MM')
        correctDateFormat = startWeekDay + ' ' + startday + ' - ' + endWeekDay + ' ' + endday
        console.log(startWeekDay + startday, endWeekDay + endday)
        return correctDateFormat
    }

    const cancelSelection = () => {
        setShowRadio(true)
        setDateSelection(false)
    }

    return (
        <div style={{ position: 'relative' }}>
            {showDate &&
                <DateRange dateRange={props.dateRange} openDateRange={showDate} style={{
                    opacity: '0',
                    position: 'absolute',
                    cursor: 'pointer',
                    top: '-2px',
                    left: '-10px',
                    width: '0px',
                    zIndex: '2',
                }} dateChange={dateChange} />
            }
            {showRadio && <Radio onChange={handleRadioChange}>Stay</Radio>}
            {showDateSelection && <div className="display___dates" onClick={cancelSelection}><p>{dates} </p> <CloseCircleOutlined style={{ color: 'red', paddingRight: '3px', cursor: 'pointer' }} /></div>}

        </div>
    )

}

export default ButtonDate

