import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './routebanner.scss';
import UserAction from '../../../CommonComponents/useraction/useraction';
const RouteBanner = () => {

    let routePlan = useSelector((state: any) => state.pat.routePlan)
    let tripDetails = useSelector((state: any) => state.pat.tripDetails)
    const topic_items = [
        { id: 1, name: 'Route', path: '/' },
        { id: 2, name: 'Stay', path: '/recommend' },
        { id: 3, name: 'Food', path: '/' },
        { id: 5, name: 'Attractions', path: '/attractions' }
    ];
    return (
        <div>
            <div className="route__banner__container">
                <div className="navbar">
                    <div className="Logo">
                        <img src="./LogoWhite.png" alt="logo" />
                    </div>
                    <UserAction />
                </div>
                <div className="route__definition__container">
                    <div className="type_of_trip">Planned Trip</div>
                    <h1 className="destination_names">
                        {routePlan.map((path: { id: string, name: string }, index: number) => {
                            return (
                                index < routePlan.length - 1 ? <span key={path.id}>{path.name}-</span> : <span key={path.id}>{path.name}</span>
                            )
                        })}
                    </h1>
                    {tripDetails.headCount && <div className="trip__info">
                        <p>{tripDetails.startDate}- {tripDetails.EndDate}</p> | <p>{tripDetails.headCount.Adult}?Adults-{tripDetails.headCount.Children}?Children</p> |
                        {tripDetails.typeOfTravel.map((type: any, index: number) => {
                            return (
                                index < tripDetails.length - 1 ? <div className="typeOfTravel__seperator">
                                    <span>{type}</span> <span>|</span>
                                </div> : <div className="typeOfTravel__seperator" key={index}> <span >{type}</span> </div>
                            )
                        })}
                    </div>}

                    <div className="edit">Edit</div>
                </div>
            </div>

            <div className="plan_topic_conatiner">
                {topic_items.map(({ id, path, name }) => (
                    <Link key={id} to={path} className="item">
                        {name}
                        <div className="item__UnderLine"></div>
                    </Link>
                ))}
            </div>
        </div>
    )

}
export default RouteBanner