// import { useState } from "react";
import './map_component.scss'
import { GoogleMap, MarkerF, DirectionsRenderer, } from '@react-google-maps/api';
import { useEffect, useState } from 'react';
const MapComponent = ({ route, size, zoomControl }: any) => {
    console.log(route)
    const [customRoute, setCustomRoute] = useState(route);
    useEffect(() => {
        setCustomRoute(route)
    }, [route])
    // console.log(route.routes[0].overview_polyline.points)
    // var encoded: string = route.routes[0].overview_polyline.points;
    // const path = google.maps.geometry.encoding.decodePath(encoded);
    // var snappedPolyline = new google.maps.Polyline(path)
    // console.log(path)
    // const [directionResponse, setDirectionResponse] = useState<google.maps.DirectionsResult>();
    const center = { lat: route.routes[0].legs[0].start_location.lat, lng: route.routes[0].legs[0].start_location.lng }
    // console.log(center)
    // const onLoad = (polyline: any) => {
    //     console.log('polyline: ', polyline)
    // };
    // const options = {
    //     strokeColor: '#FF0000',
    //     strokeOpacity: 0.8,
    //     strokeWeight: 2,
    //     fillColor: '#FF0000',
    //     fillOpacity: 0.35,
    //     clickable: false,
    //     draggable: false,
    //     editable: false,
    //     visible: true,
    //     radius: 300000,
    //     paths: path,
    //     zIndex: 10
    // };
    // var src = new google.maps.LatLng(route.routes[0].legs[0].start_location.lat, route.routes[0].legs[0].start_location.lng);
    // var dest = new google.maps.LatLng(route.routes[0].legs[0].end_location.lat, route.routes[0].legs[0].end_location.lng);

    // const calculateRoute = async () => {
    //     const directionService = new google.maps.DirectionsService()
    //     const results: google.maps.DirectionsResult = await directionService.route({
    //         origin: src,
    //         destination: dest,
    //         // eslint-disable-next-line no-undef
    //         travelMode: google.maps.TravelMode.DRIVING,
    //         provideRouteAlternatives: true
    //     })
    //     console.log(results)
    //     setDirectionResponse(results)
    // }
    // calculateRoute()
    // console.log(results)

    // setDistance(results.routes[0].legs[0].distance.text);
    // setDuration(results.routes[0].legs[0].duration.text)

    // const clearRoute = () => {
    //     setDistance('');
    //     setDuration('');
    //     // originRef = '';
    //     // destinationRef = '';
    //     // map.panTo({center});
    // }
    const options: google.maps.MapOptions = {
        streetViewControl: false,
        fullscreenControl: false,
        zoomControl: zoomControl,
        mapTypeControl: false,
        gestureHandling: "cooperative",
    }
    return (
        <div>
            <GoogleMap
                center={center}
                zoom={6}
                mapContainerStyle={size ? size : { width: '100%', height: '100%', position: 'absolute', borderRadius: '10px' }}
                options={options}
            >
                <MarkerF position={center} />
                {route && <DirectionsRenderer directions={customRoute} />}
                {/* {path && <PolylineF path={path}
                    onLoad={onLoad}
                    options={options}
                />} */}
            </GoogleMap>
        </div>
    )
}

export default MapComponent;