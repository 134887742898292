import React, { useState } from "react";
import './customroute.scss';
import MapComponent from '../../../../CommonComponents/mapcomponent/map_component';
import AddStopItem from "../../../../CommonComponents/RouteModuleComponents/AddStopsItems/addStop.component";
import Search from "antd/es/input/Search";
import { useDispatch, useSelector } from "react-redux";
import { getDatesFromDateRange } from "../../routeplan.services";
import { setRoutePath } from "../../../../redux/planatripredux/pat_slice";
const CustomRoute = ({ defaultRoute, routePlan, addRemoveStops }: any) => {
    console.log(routePlan[0].name)
    // let RouteList = [
    //     {
    //         id: 1,
    //         addStop: 'Bangalore'
    //     },
    //     {
    //         id: 2,
    //         addStop: 'Mysore'
    //     },
    //     {
    //         id: 3,
    //         addStop: 'Coorg'
    //     },
    //     {
    //         id: 4,
    //         addStop: 'Madikeri'
    //     },
    //     {
    //         id: 5,
    //         addStop: 'Coorgasd'
    //     },
    //     {
    //         id: 6,
    //         addStop: 'Mandya'
    //     },
    //     {
    //         id: 7,
    //         addStop: 'Chikmagalur'
    //     },
    //     {
    //         id: 8,
    //         addStop: 'Dandeli'
    //     },
    //     {
    //         id: 9,
    //         addStop: 'Goa'
    //     },
    //     {
    //         id: 10,
    //         addStop: 'Pune'
    //     }, {
    //         id: 11,
    //         addStop: 'Dandeli'
    //     },
    //     {
    //         id: 12,
    //         addStop: 'Goa'
    //     },
    //     {
    //         id: 13,
    //         addStop: 'Pune'
    //     }
    // ]
    const [stopsList, setStopsList] = useState(routePlan)
    const [createRouteExpand, setcreateRouteExpand] = useState(false);
    const [stopsInput, showStopsInput] = useState(false);
    const [dragStartIndex, setDragStartIndex] = useState(0);
    const [dragOverIndex, setDragOverIndex] = useState(0);
    const tripDetails = useSelector((state: any) => state.pat.tripDetails)
    const dateRange = tripDetails.find((details: any) => details.id === 'dates').dateRange
    const dates: Array<string> = getDatesFromDateRange(dateRange)
    const dispatch = useDispatch();
    const onDraggingStart = (index: number) => {
        console.log(index)
        if (index !== 0) {
            setDragStartIndex(index)
        }

    }
    const onAddStop = (value: string) => {
        let list = [...stopsList]
        list.splice(-1, 0, { id: value, name: value })
        setStopsList(list)
        addRemoveStops(list)
        console.log(list)
        showStopsInput(false)
        dispatch(setRoutePath([...list]))
    }

    const onDraggingStop = () => {
        //get droped item
        console.log(dragStartIndex, dragOverIndex)

        //get dragged Item
        const draggedStops = stopsList[dragStartIndex]

        //remove dragged Item
        let list = [...stopsList]
        list.splice(dragStartIndex, 1)
        //update list

        //handle dragdown
        if (dragOverIndex !== 0) {
            console.log(...list.slice(0, dragOverIndex))
            console.log(draggedStops)
            console.log(...list.slice(dragOverIndex, list.length))
            list = [...list.slice(0, dragOverIndex), draggedStops, ...list.slice(dragOverIndex, list.length)]
            setStopsList(list)
            addRemoveStops(list)
        }

        console.log(stopsList)
    }

    const deleteRoute = (index: number) => {
        let list = [...stopsList]
        list.splice(index, 1)
        setStopsList([...list])
        addRemoveStops(list)
        dispatch(setRoutePath([...list]))
    }

    return (
        <div className="route__item_custom">
            <div className="add__route__container">
                <img src="./icons/addRoute.png" alt="addRouteIcon" onClick={() => { setcreateRouteExpand(true) }} />
                <p>Create your route</p>
            </div>
            {createRouteExpand && <div className="route_map_container__expaned__custom">
                <div className='expandCloseButton__custom' ><h3>Create your route</h3><button onClick={() => setcreateRouteExpand(false)}>X</button></div>
                <div className='custom_route_edit_container'>
                    <div className="edit_route">
                        <div className="startLabel">
                            <p>Start: {routePlan[0].name}</p>
                            <div className="add__stops" onClick={() => showStopsInput(true)}><img src='/icons/plus-cirlce-outlined.png' alt="plusIcon" /> Add stops </div>
                        </div>
                        <div className="stops__scrolable">
                            <div className="draggable__offsetParent">
                                {stopsList.map((stops: { id: string, name: string }, index: number) => {
                                    return (<AddStopItem key={stops.id} stops={stops.name} index={index}
                                        onDraggingStart={onDraggingStart}
                                        onDraggingStop={onDraggingStop}
                                        onDraggingOver={(index: number) => index !== 0 ? setDragOverIndex(index) : 0}
                                        editDrag={(index === 0 || index === stopsList.length - 1) ? false : true}
                                        deleteRoute={deleteRoute}
                                        dates={dates} />)
                                })}
                            </div>
                            {stopsInput && <Search
                                placeholder="Add Stop"
                                allowClear
                                enterButton="Add Stop"
                                size="large"
                                onSearch={onAddStop}
                            />}
                        </div>

                    </div>
                    <div className='mapContainer__custom'><MapComponent zoomControl={true} size={{ width: '98%', height: '90%', position: 'absolute', borderRadius: '10px' }} route={defaultRoute} /></div>
                </div>
                <div className="save__cancel">
                    <button onClick={() => setcreateRouteExpand(false)}>Cancel</button>
                    <button>Save</button>
                </div>
            </div>}

        </div >

    )
}

export default CustomRoute

