import React from 'react';
import HMBanner from './banner/banner';
import './hm_footer.scss';
import { Link } from 'react-router-dom';

const HMFooter = () => {
  return (
    <div className="footer__container">
      <HMBanner />
      <div className="footer__content">
        <img className="FT_LOGO" src="./footer/FT_footerlogo.png" alt="" />
        <div className="content">
          <div className="sub_points">
            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <Link style={{ textDecoration: 'none', color: 'black', fontWeight: 'bolder' }} to="">About Us</Link>
              <Link style={{ textDecoration: 'none', color: 'black', fontWeight: 'bolder' }} to="/T&C">Terms&Conditions</Link>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <Link style={{ textDecoration: 'none', color: 'black', fontWeight: 'bolder' }} to="">Careers</Link>
              <Link style={{ textDecoration: 'none', color: 'black', fontWeight: 'bolder' }} to="/privatepolicy">Privacy Policy</Link>

            </div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <Link style={{ textDecoration: 'none', color: 'black', fontWeight: 'bolder' }} to="">FAQs</Link>
              <Link style={{ textDecoration: 'none', color: 'black', fontWeight: 'bolder' }} to="/cookiepolicy">Cookie Policy</Link>

            </div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <Link style={{ textDecoration: 'none', color: 'black', fontWeight: 'bolder' }} to="">Gift Cards</Link>
              <Link style={{ textDecoration: 'none', color: 'black', fontWeight: 'bolder' }} to="/refundpolicy">Refund Policy</Link>

            </div>
          </div>
          <p>© 2022 Food Tolls. All Rights Reserved.</p>
        </div>
        <div className="socialMedia__icon">
          <img src="./footer/socialMediaicon.png" alt="" />
        </div>
      </div>
    </div>
  );
};

export default HMFooter;
