
const GoogleApikey: string = 'AIzaSyAvQ0SRLN1rzwAFCbXTJ_OBaTrHcQhj6qU';
//Fetch GmapRoute
export const fetchDirection = async ({ srcLocation, destinationInput, selectOption, wayPoints }: any) => {
  console.log(selectOption)
  // const wypnts = [{ location: 'Mandya', stopover: true }]
  if (wayPoints) {
    console.log('Hi')
    wayPoints = await setWayPoints(wayPoints)
    console.log(wayPoints)
  }
  const directionService = new google.maps.DirectionsService()
  const results: google.maps.DirectionsResult = await directionService.route({
    origin: srcLocation,
    destination: destinationInput,
    waypoints: wayPoints ? wayPoints : [],
    // eslint-disable-next-line no-undef
    travelMode: google.maps.TravelMode.DRIVING,
    provideRouteAlternatives: true
  })
  // const url = 'http://localhost:4000/map/getRoutes'
  // const response = await fetch(url, {
  //   method: "POST",
  //   headers: {
  //     Accept: "application/json",
  //     'Content-Type': 'application/json'
  //   },
  //   body: JSON.stringify({
  //     src: srcLocation,
  //     dest: destinationInput,
  //     filters: selectOption
  //   })
  // })
  // const data = await response.json();
  // console.log('client:', results)
  const routeList = await reAssignRoute(results);
  console.log(routeList)
  return routeList;
}

//split routes into multiple route
const reAssignRoute = (gmapRouteData: any) => {
  let routeList: any = [];
  if (gmapRouteData.routes.length > 1) {
    gmapRouteData.routes.forEach((route: any, index: number) => {
      let tempData = JSON.parse(JSON.stringify(gmapRouteData))
      tempData.routes = tempData.routes.slice(index, index + 1)
      routeList.push(tempData);
    })
  } else {
    routeList = [gmapRouteData]
  }

  return routeList;
}

//Add waypoints to search
const setWayPoints = (WayPoints: any) => {
  let wayPointsList: { location: string, stopover: boolean }[]
  wayPointsList = []
  console.log(WayPoints)
  WayPoints.forEach((wayPoint: any) => {
    wayPointsList.push({ location: wayPoint, stopover: true })
  })
  return wayPointsList;
}


//Device Location
export const fetchLocationLATLONG = () => {
  return new Promise((res, rej) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(res);
    } else {
      rej("Unable to find current location");
    }

  });
}


export const showPosition = async (position: { coords: { latitude: any; longitude: any; }; }) => {
  let lat = position.coords.latitude;
  let lng = position.coords.longitude;
  const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${GoogleApikey}`;
  const response = await fetch(url);
  const data = await response.json();
  const data_1 = data;
  const primeRes = data_1.results[0];
  const Location = {
    formatted_address: primeRes.formatted_address.split(',').splice(1).join(),
    locationLatLng: primeRes.geometry.location,
    city: await getCity(primeRes)
  };
  return Location;
}

const getCity = async (res: any) => {
  const city = await res.address_components.find((address: any) => {
    return address.types.includes('locality')
  })
  return city.short_name;
}






