import React, { useRef } from "react";
import ButtonDate from "../../button_date/button_date.component";
import './addStop.styles.scss'
const AddStopItem = ({ stops, editDrag, index, deleteRoute, onDraggingStart, onDraggingOver, onDraggingStop, dates }: dragType) => {
    console.log(dates)
    const stopsItemRef = useRef<any>(null);
    // const tripDetails = useSelector((state: any) => state.pat.tripDetails)
    // const dateRange = tripDetails.find((details: any) => details.id === 'dates').dateRange
    const onDragStart = (e: any, index: number) => {
        //remove default drag ghost
        console.log(e)
        if (e.type !== 'touchstart') {
            e.dataTransfer.effectAllowed = 'move'
            e.dataTransfer.setDragImage(e.target, 50000, 50000)
        }
        //customer drag ghost
        let ghostNode = e.target.cloneNode(true)
        ghostNode.style.position = "absolute"

        //show ghost add mouse pointer position
        if (e.type === 'touchstart') {
            console.log(e)
            ghostNode.style.top = (e.changedTouches[0].clientY - e.target.offsetHeight / 2) + 'px'
            ghostNode.style.left = (e.changedTouches[0].clientX - e.target.offsetWidth / 2) + 'px'
            ghostNode.style.zIndex = '15';
        } else {
            ghostNode.style.top = (e.pageY - e.target.offsetHeight / 2) + 'px'
            ghostNode.style.left = (e.pageX - e.target.offsetWidth / 2) + 'px'
            ghostNode.style.zIndex = '15';
            console.log(ghostNode)
        }


        //add width height to ghost node
        // ghostNode.style.height = e.target.offsetHeight + 'px';
        ghostNode.style.width = e.target.offsetWidth + 'px';

        //add same stye
        ghostNode.style.opacity = '1'
        ghostNode.style.pointerEvents = 'none'

        //add id
        ghostNode.id = 'ghostNode'

        document.body.prepend(ghostNode)

        //identify selected Item
        if (stopsItemRef) {
            stopsItemRef.current?.classList.add('onDragStart');
        }
        if (onDraggingStart) {
            onDraggingStart(index)
        }
    }

    const onDrag = (e: any) => {
        let ghostNode: any = document.querySelector('#ghostNode')
        if (ghostNode) {
            if (e.type === 'touchmove') {
                ghostNode.style.top = (e.changedTouches[0].clientY - e.target.offsetHeight / 2) + 'px'
                ghostNode.style.left = (e.changedTouches[0].clientX - e.target.offsetWidth / 2) + 'px'
                ghostNode.style.zIndex = '15';
            } else {
                ghostNode.style.top = (e.pageY - e.target.offsetHeight / 2) + 'px'
                ghostNode.style.left = (e.pageX - e.target.offsetWidth / 2) + 'px'
                ghostNode.style.zIndex = '15';
            }
        }
    }

    //event when drag over item
    const onDragEnter = (e: any, index: number) => {
        let ghostNode: any = document.querySelector('#ghostNode')
        if (e.type === 'touchmove') {
            ghostNode.style.top = (e.changedTouches[0].clientY - e.target.offsetHeight / 2) + 'px'
            ghostNode.style.left = (e.changedTouches[0].clientX - e.target.offsetWidth / 2) + 'px'
            ghostNode.style.zIndex = '15';
        }
        ghostNode.style.boxShadow = ('0 0 4px 0px #9d9d9d')
        if (stopsItemRef) {
            stopsItemRef.current?.classList.add('onDragOver')
        }
        onDraggingOver(index)
    }
    const onDragLeave = () => {
        if (stopsItemRef) {
            stopsItemRef.current?.classList.remove('onDragOver')
        }

    }
    const onDrop = () => {
        if (stopsItemRef) {
            stopsItemRef.current?.classList.remove('onDragOver')
        }
    }

    const onDragEnd = () => {
        //remove selected item style
        if (stopsItemRef) {
            stopsItemRef.current?.classList.remove('onDragStart')
        }
        //remove ghose node
        document.querySelector('#ghostNode')?.remove()

        if (onDraggingStop) {
            onDraggingStop()
        }
    }
    const onDateSelection = (startDate: any, endDate: any) => {
        console.log(startDate, endDate)
        //check startDate available
        if (dates.find(date => date === startDate)) {
            console.log('start date exists', stops)
        }
    }

    return (
        <div onDragStart={(e) => onDragStart(e, index)}
            onDrag={onDrag}
            onDragEnter={(e) => onDragEnter(e, index)}
            onDragLeave={() => onDragLeave()}
            onDrop={onDrop}
            onDragEnd={onDragEnd}
            onTouchStart={(e) => onDragStart(e, index)}
            onTouchMove={(e) => onDragEnter(e, index)}
            onTouchEnd={onDragEnd}
            draggable={editDrag} ref={stopsItemRef} className="stop__item__container">
            <div className="stop__space"><span className="index__highlight">
                <img src='/icons/dashes_opiton.png' draggable='false' alt="selectOption" /></span>
                <span className="stop__gap">{stops}</span>
            </div>
            <ButtonDate dateRange={dates} onDateSelection={onDateSelection} />
            {editDrag && <div onClick={() => deleteRoute(index)}><img src='/icons/plain-delete.png' alt='deleteButton' /></div>}
        </div>
    )
}

export default AddStopItem;

interface dragType {
    dates: string[]
    stops: string,
    editDrag: boolean,
    index: number,
    deleteRoute: Function,
    onDraggingStart: Function,
    onDraggingOver: Function,
    onDraggingStop: Function
}